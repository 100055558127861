import React, { useEffect, useState } from "react";
// import Breadcrumbs from "../common/Breadcrumbs";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../environment";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
// import { all_routes } from "../router/all_routes";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneNumberField from "../home/phoneNumberField";

const listingDetails = () => {
  // const routes = all_routes
  // const bigImgSliderRef = useRef(null);
  // const thumbnailSliderRef = useRef(null);
  const [packagesList,setPackagesList] = useState<any>([])
  // const [relatedPackagesList,setRelatedPackagesList] = useState<any>([])
  const [isloading, setIsLoading] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { id }:any = useParams();
  const fetchPackagesList = async (id: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/packages/${id}`);
      setPackagesList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const fetchRelatedPackagesList = async (make: string) => {
  //   try {
  //     const response = await axios.post(`${API_BASE_URL}/packages/list`, {
  //       vehicle_type: make,
  //     });
  //     setRelatedPackagesList(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchRelatedPackagesList('')
  // }, []);
  
  useEffect(() => {
    fetchPackagesList(id);
  }, [id]);


  const schemaWithPersonalDetails = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_no: Yup.string().required("Phone number is required"),
  });

  const {
    handleSubmit: handlePackageSubmit,
    control: handleController,
    formState: { errors: personalDetailsErrors },
    reset,
  } = useForm({
    resolver: yupResolver(schemaWithPersonalDetails),
  });

  
  const onSubmitPersonalDetails = async (bookingFormData: any) => {
    setIsLoading(true);
    setIsBookingModalOpen(true);
    try {
      const payload = {
        package_id: packagesList.id,
        pickup_date_time: packagesList.pickup_date_time,
        baby_seat: packagesList.vehicle_detail.baby_seat,
        phone_no: bookingFormData.phone_no,
        name: bookingFormData.name,
        email: bookingFormData.email,
        user_id: "",
      };
      const response = await axios.post(
        `${API_BASE_URL}/packages/booking`,
        payload
      );
      if (response.status != 400) {
        setErrorMessage("");
        setSuccessMessage(response.data.message);
        setIsLoading(false);
      } else {
        setIsBookingModalOpen(true);
        setErrorMessage(response.data.message);
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
        setIsBookingModalOpen(true);
        setSuccessMessage("");
        setIsLoading(false);
      } else {
        setIsBookingModalOpen(true);
        setErrorMessage("An error occurred. Please try again later.");
        setIsLoading(false);
      }
    } finally {
      reset();
    }
  };

  // const bigImgSettings = {
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };


  // const settings = {
  //   dots: false,
  //   autoplay: false,
  //   slidesToShow: 1,
  //   speed: 500,
  // };


 const closeBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  // useEffect(() => {
  //   if (bigImgSliderRef.current && thumbnailSliderRef.current) {
  //     bigImgSliderRef.current.slickGoTo(0);
  //     thumbnailSliderRef.current.slickGoTo(0);
  //   }
  // }, []);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);
  return (

    <>
     {/* Booking Status Modal */}
     {isBookingModalOpen && (
        <div
          className={` ${isloading ? "pointer-event-none" : ""} modal new-modal fade d-block show`}
          id="delete_modal"
          data-keyboard="false"
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="delete-action">
                  <div className="delete-header">
                    <h4>Booking Status</h4>
                  </div>
                  {isloading && (
                    <p className="py-4">
                      Please wait your booking is in progress ...
                    </p>
                  )}

                  {successMessage && (
                    <p className="py-4 text-success">{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p className="py-4 text-danger">{errorMessage}</p>
                  )}

                  <div className="modal-btn">
                    <div className="row">
                      <div className="col-6 m-auto">
                        <button
                          onClick={closeBookingModal}
                          className="btn btn-primary w-100"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isBookingModalOpen && <div className="modal-backdrop fade show"></div>}
      
    <div className="main-wrapper">
      {/* <Breadcrumbs title={packagesList?.vehicle_detail?.name} subtitle="Package" /> */}
      <section className="product-detail-head">
        <div className="container">
          <div className="detail-page-head">
            <div className="detail-headings">
              <div className="star-rated">
                <div className="list-rating">
                  <span className="year">2024</span>
                  <i className="fas fa-star filled me-1"></i>
                  <span className="d-inline-block average-list-rating">
                    {" "}
                   {packagesList?.vehicle_detail?.rating}{" "}
                  </span>
                </div>
                <div className="camaro-info">
                  <h3> {packagesList?.vehicle_detail?.name}</h3>
                  <div className="camaro-location">
                    <div className="camaro-location-inner">
                      <i className="feather icon-map-pin me-2"></i>
                      <span className="me-2">
                        {" "}
                        <b>Location :</b> {packagesList?.pickup_location?.name}{" to "}
                        {packagesList?.drop_location?.name}
                      </span>
                    </div>
                    {/* <div className="camaro-locations-inner">
                      <i className="feather icon-eye me-2"></i>
                      <span>
                        <b>Views :</b> 250{" "}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="details-btn">
              {/* <Link to="#">
                {" "}
                <ImageWithBasePath
                  src="assets/img/git-compare.svg"
                  alt="img"
                />{" "}
                Compare
              </Link> */}
              <Link to="#">
                <i className="feather icon-heart"></i> Wishlist
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section product-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="detail-product">
                {/* <Slider className="detail-bigimg" {...bigImgSettings}> */}
                  <div className="product-img">
                    <img className="w-100" src={packagesList?.image} alt="Car Image" />
                  </div>
                {/* </Slider> */}
               
              </div>
              {/* <div className="review-sec extra-service">
                <div className="review-header">
                  <h4>Extra Service</h4>
                </div>
                <span>Baby Seat - $10</span>
              </div> */}
              {/*Listing Features Section*/}
              <div className="review-sec specification-card ">
                <div className="review-header">
                  <h4>Specifications</h4>
                </div>
                <div className="card-body">
                  <div className="lisiting-featues">
                    <div className="row">
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.baby_seat_icon}
                            alt="spec"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.baby_seat === 'true'?'Yes':'No'}</span>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.fuel_type_icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.fuel_type}</span>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.luggage_capacity_icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.luggage_capacity}</span>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.passenger_capacity_icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.passenger_capacity}</span>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.vehicle_type?.icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.total_rides}</span>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.vehicle_type?.connectivity_icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.vehicle_type?.connectivity}</span>
                        </div>
                      </div>
                      
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.vehicle_type?.safety_icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.vehicle_type?.safety}</span>
                        </div>
                      </div>
                      <div className="featureslist d-flex align-items-center col-lg-3 col-md-4">
                        <div className="feature-img">
                          <ImageWithBasePath
                            src={packagesList?.vehicle_detail?.vehicle_type?.comfort_icon}
                            alt="specification"
                          />
                        </div>
                        <div className="featues-info">
                          <span>{packagesList?.vehicle_detail?.vehicle_type?.comfort}</span>
                        </div>
                      </div>
                    
                     
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="review-sec listing-feature">
                <div className="review-header">
                  <h4>Car Features</h4>
                </div>
                <div className="listing-description">
                  <div className="row">
                    <div className="col-md-4">
                      <ul>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Multi-zone A/C
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Heated front seats
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Andriod Auto
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Navigation system
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Premium sound system
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Bluetooth
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Keyles Start
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Memory seat
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          6 Cylinders
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Adaptive Cruise Control
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          Intermittent wipers
                        </li>
                        <li>
                          <span>
                            <i className="fa-solid fa-check-double" />
                          </span>
                          4 power windows
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*/Listing Features Section*/}
              <div className="review-sec extra-service mb-0">
                <div className="review-header">
                  <h4>Package Description</h4>
                </div>
                <div className="description-list">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industrys standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged.
                  </p>
                </div>
              </div>
              {/* <div className="review-sec extra-service mb-0">
                <div className="review-header">
                  <h4>Video</h4>
                </div>
                <div className="short-video">
                  <iframe
                    src="https://www.youtube.com/embed/ExJZAegsOis"
                    width={100}
                    height={350}
                  />
                </div>
              </div> */}
              <div className="review-sec listing-review">
                <div className="review-header">
                  <h4>
                    Reviews<span className="me-2">(2)</span>
                  </h4>
                  <div className="reviewbox-list-rating">
                    <p>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <span> (5 out of 5)</span>
                    </p>
                  </div>
                </div>
                <div className="review-card">
                  <div className="review-header-group">
                    <div className="review-widget-header">
                      <span className="review-widget-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-01.jpg"
                          className="img-fluid"
                          alt='img'
                        />
                      </span>
                      <div className="review-design">
                        <h6>Johnson</h6>
                        <p>02 Jan 2023</p>
                      </div>
                    </div>
                    <div className="reviewbox-list-rating">
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <span> (5.0)</span>
                      </p>
                    </div>
                  </div>
                  <p>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently
                    with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.It was popularised in the
                    1960s{" "}
                  </p>
                  <ul className="review-list-rating">
                    <li>
                      quality
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                    <li>
                      Price
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                    <li>
                      Comfort
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                    <li>
                      Driving
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="review-card">
                  <div className="review-header-group">
                    <div className="review-widget-header">
                      <span className="review-widget-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-02.jpg"
                          className="img-fluid"
                          alt='img'
                        />
                      </span>
                      <div className="review-design">
                        <h6>Casandra</h6>
                        <p>02 Jan 2023</p>
                      </div>
                    </div>
                    <div className="reviewbox-list-rating">
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <span> (5.0)</span>
                      </p>
                    </div>
                  </div>
                  <p>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently
                    with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.It was popularised in the
                    1960s{" "}
                  </p>
                  <ul className="review-list-rating">
                    <li>
                      quality
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                    <li>
                      Price
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                    <li>
                      Comfort
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                    <li>
                      Driving
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                        <i className="fas fa-star filled me-1"></i>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="review-sec leave-reply-form">
                <div className="review-header">
                  <h4>Leave a Reply</h4>
                </div>
                <ul className="review-list-rating mb-3">
                  <li>
                    quality
                    <p>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                    </p>
                  </li>
                  <li>
                    Price
                    <p>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                    </p>
                  </li>
                  <li>
                    Comfort
                    <p>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                    </p>
                  </li>
                  <li>
                    Driving
                    <p>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                      <i className="fas fa-star filled me-1"></i>
                    </p>
                  </li>
                </ul>
                <div className="card-body">
                  <div className="review-list">
                    <ul>
                      <li className="review-box feedbackbox mb-0">
                        <div className="review-details">
                          <form className="#">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="input-block">
                                  <label>
                                    Full Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-block">
                                  <label>
                                    Email Address{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="input-block">
                                  <label>Comments </label>
                                  <textarea
                                    rows={4}
                                    className="form-control"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="submit-btn">
                              <button
                                className="btn btn-primary submit-review"
                                type="submit"
                              >
                                {" "}
                                Submit Review
                              </button>
                            </div>
                          </form>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-lg-4 theiaStickySidebar">
              <div className="stickysidebar">
                
                <div className="review-sec mt-0">
                  <div className="review-header">
                    <h4>Book Now</h4>
                  </div>
                  <div>
                  <form onSubmit={handlePackageSubmit(onSubmitPersonalDetails)}>
                      <ul>
                        <li className="column-group-main">
                          <div className="input-block">
                            <label>Enter Name</label>
                            <div className="group-img">
                              <Controller
                                name="name"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.name && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.name.message}
                              </p>
                            )}
                          </div>
                        </li>
                        <li className="column-group-main">
                          <div className="input-block">
                            <label>Enter Email</label>
                            <div className="group-img">
                            <Controller
                                name="email"
                                control={handleController}
                                render={({ field }) => (
                                  <>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Email"
                                      {...field}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {personalDetailsErrors.email && (
                              <p className="text-danger pt-1">
                                {personalDetailsErrors.email.message}
                              </p>
                            )}
                          </div>
                        </li>
                        <li className="column-group-main">
                          <div className="input-block">
                            <label>Enter Phone</label>
                            <div className="search-box-banner p-0 m-0">
                              <div className="input-block column-group-main max-width-100">

                              
                            <div className="group-img phone-input-style PhoneInput">
                                <Controller
                                  name="phone_no"
                                  control={handleController}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <PhoneNumberField
                                      onChange={field.onChange}
                                    />
                                  )}
                                />
                              </div>
                              </div>
                            
                            </div>
                            {personalDetailsErrors.phone_no && (
                                <p className="text-danger pt-1">
                                  {personalDetailsErrors.phone_no.message}
                                </p>
                              )}
                          </div>
                        </li>
                        <li className="column-group-last">
                          <div className="input-block mb-0">
                            <div className="search-btn">
                           
                              <button
                               disabled={isloading ? true : false}
                               type="submit"
                                className="btn btn-primary check-available w-100"
                              >
                                {" "}
                                {isloading ? "Please wait" : "Confirm Booking"}
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
                <div className="review-sec extra-service mt-0">
                  <div className="review-header">
                    <h4>Contact us</h4>
                  </div>
                  <div className="owner-detail">
                    <div className="owner-img">
                      <Link to="#">
                        <ImageWithBasePath src={packagesList?.image} alt='img' />
                      </Link>
                    </div>
                    <div className="reviewbox-list-rating">
                      <h5>
                        <Link to="#">{packagesList?.vehicle_detail?.name}</Link>
                      </h5>
                      <p>
                        <i className="fas fa-star filled me-1"></i>
                        <span> ({packagesList?.vehicle_detail?.rating})</span>
                      </p>
                    </div>
                  </div>
                  <ul className="booking-list">
                    <li>
                      Total Rides
                      <span>{packagesList?.vehicle_detail?.total_rides}</span>
                    </li>
                    <li>
                      Verification
                      <h6>Verified</h6>
                    </li>
                  </ul>
                  <div className="message-btn">
                    <Link target="_blank" to="https://wa.me/+923044256771" className="btn btn-order">
                      WhatsApp Now
                    </Link>
                  </div>
                </div>
                <div className="review-sec share-car mt-0">
                  <div className="review-header">
                    <h4>Share this car</h4>
                  </div>
                  <ul className="nav-social">
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook-f fa-facebook fi-icon">
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-instagram fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-behance fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-pinterest-p fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter fi-icon" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-linkedin fi-icon" />
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="review-sec share-car mt-0 mb-0">
                  <div className="review-header">
                    <h4>View Location</h4>
                  </div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                    className="iframe-video"
                  />
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <div className="details-car-grid">
                <div className="details-slider-heading">
                  <h3>You May be Interested in</h3>
                </div>
            
                <div className="car-details-slider owl-carousel">
                  <Slider {...settings}>
                    <div>
                  {relatedPackagesList.map((item: any, index: number) => (
<>
                    <div className="card" key={index}>
                    <div className="listing-item pb-0">
                              <div className="listing-img">
                                <Link to={`${routes.listingdetails}/${item?.id}`}>
                                  <ImageWithBasePath
                                    src={item?.image}
                                    className="img-fluid"
                                    alt="Toyota"
                                  />
                                </Link>
                                <div
                                  className="fav-item"
                                >
                                  <span className="featured-text">
                                    {item?.vehicle_detail.vehicle_type.name}
                                  </span>
                                  <Link
                                    to={`${routes.listingdetails}/${item?.id}`}
                                  >
                                    <i className="feather icon-heart" />
                                  </Link>
                                </div>
                              </div>
                              <div className="listing-content">
                                <div className="listing-features">
                                  <Link to={`${routes.listingdetails}/${item?.id}`} className="author-img">
                                    <ImageWithBasePath
                                      src={item?.vehicle_detail?.vehicle_type?.icon}
                                      alt="author"
                                    />
                                  </Link>
                                  <h3 className="listing-title">
                                    <Link to={routes.listingdetails}>
                                      {item?.vehicle_detail?.name}
                                    </Link>
                                  </h3>
                                  <div className="list-rating">
                                    <span className="featured-text">
                                      {item?.vehicle_detail?.total_rides}+ Rides
                                    </span>
                                    <span className="featured-text">
                                      Ratings ({item?.vehicle_detail?.rating})
                                    </span>
                                  </div>
                                </div>
                                <div className="listing-details-group">
                                  <ul>
                                    <li>
                                      <span>
                                        <ImageWithBasePath
                                          src={item?.vehicle_detail?.vehicle_type?.connectivity_icon}
                                          alt="Auto"
                                        />
                                      </span>
                                      <p>
                                        {
                                          item.vehicle_detail.vehicle_type
                                            .connectivity
                                        }
                                      </p>
                                    </li>
                                    <li>
                                      <span>
                                        <ImageWithBasePath
                                          src={item?.vehicle_detail.luggage_capacity_icon}
                                          alt="10 KM"
                                        />
                                      </span>
                                      <p>
                                        {item?.vehicle_detail?.luggage_capacity}
                                      </p>
                                    </li>
                                    <li>
                                      <span>
                                        <ImageWithBasePath
                                          src={item?.vehicle_detail.fuel_type_icon}
                                          alt="Petrol"
                                        />
                                      </span>
                                      <p>{item?.vehicle_detail?.fuel_type}</p>
                                    </li>
                                  </ul>
                                  <ul>
                                    <li>
                                      <span>
                                        <ImageWithBasePath
                                          src={item?.vehicle_detail?.vehicle_type?.comfort_icon}
                                          alt="confort"
                                        />
                                      </span>
                                      <p>
                                        {
                                          item.vehicle_detail.vehicle_type
                                            .comfort
                                        }
                                      </p>
                                    </li>
                                    <li>
                                      <span>
                                        <ImageWithBasePath
                                          src={item?.vehicle_detail?.vehicle_type?.safety_icon}
                                          alt=""
                                        />
                                      </span>
                                      <p>
                                        {
                                          item.vehicle_detail.vehicle_type
                                            .safety
                                        }
                                      </p>
                                    </li>
                                    <li>
                                      <span>
                                        <ImageWithBasePath
                                          src={item?.vehicle_detail.passenger_capacity_icon}
                                          alt="Persons"
                                        />
                                      </span>
                                      <p>
                                        {
                                          item?.vehicle_detail
                                            ?.passenger_capacity
                                        }
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                                <div className="listing-location-details justify-content-center">
                                  <div className="listing-price text-center">
                                    <h6>{item?.price}</h6>
                                  </div>
                                </div>
                                <div className="listing-button">
                                  <button
                                    className="btn btn-order"
                                  >
                                    {isloading ? "Please wait" : "Book Now"}
                                  </button>
                                </div>
                              </div>
                            </div>
                    </div>
                    </>
                  )
                )
                }
                  </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
    </>
  );
};

export default listingDetails;
