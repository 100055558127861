import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Breadcrumbs from "../common/Breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import axios from "axios";
import { API_BASE_URL } from "../../environment";

const Contact = () => {
  const [successMessage,setSuccessMessage]= useState('');
  const [errorMessage,setErrorMessage]= useState('');
  const [isloading,setIsLoading] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d+$/, "Phone number must contain only numbers"),
    comments: yup.string().required("Comments are required"),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${API_BASE_URL}/contact/add`,
        {
          name: data.name,
          email: data.email,
          phone_no: data.phone,
          comments: data.comments
        }
      );

      if (response.status === 200) {
        setErrorMessage('')
        setSuccessMessage(response.data.message)
        setIsLoading(false)
        setTimeout(() => {
          setSuccessMessage('')
        }, 10000);
      } else {
        setSuccessMessage('')
        setErrorMessage(response.data.message)
        setIsLoading(false)
        setTimeout(() => {
          setErrorMessage('')
        }, 10000);
        
      }
    } catch (error:any) {
      if(error.response &&
        error.response.data &&
        error.response.data.message){
          setErrorMessage(error.response.data.message);
        }
        setIsLoading(false)
        setTimeout(() => {
          setErrorMessage('')
        }, 10000);
      setIsLoading(false)
      console.error('An error occurred:', error);
    }
  };

  const Contactdata = [
    {
      type: "phone",
      icon: "feather-phone-call",
      title: "Phone Number",
      href: "https://wa.me/966580125300",
      text: "+966 580 125 300",
    },
    {
      type: "email",
      icon: "feather-mail",
      title: "Email Address",
      link: "mailto:support@alharamaincab.com",
      text: "support@alharamaincab.com",
    },
    {
      type: "location",
      icon: "feather-map-pin",
      title: "Location",
      link: "#;",
      text: "Mecca, Saudi Arabia",
    },
    {
      type: "hours",
      icon: "feather-clock",
      title: "Opening Hours",
      link: "#;",
      text: "24/7 Operation",
    },
  ];
  
  

  return (
    <div className="main-wrapper">
      <Breadcrumbs title="Contact us" />
      <section className="contact-section">
        <div className="container">
          <div className="contact-info-area">
            <div className="row">
              {Contactdata.map((info, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-12 d-flex"
                  data-aos="fade-down"
                  data-aos-duration={1200}
                  data-aos-delay="0.1"
                >
                  <div className="single-contact-info flex-fill">
                    <span>
                      <i className={info.icon} />
                    </span>
                    <h3>{info.title}</h3>
                    {info.type === "phone" ? (
                      <a href={info.href}>{info.text}</a>
                    ) : (
                      <p>
                        <a href={info.href}>{info.text}</a>
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="form-info-area"
            data-aos="fade-down"
            data-aos-duration={1200}
            data-aos-delay="0.5"
          >
            <div className="row">
              <div className="col-lg-6 d-flex">
                <ImageWithBasePath
                  src="/assets/img/gallery/contact-alharamain.jpg"
                  className="img-fluid"
                  alt="Contact"
                />
              </div>
              <div className="col-lg-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <h1>Get in touch!</h1>
                    
                    {successMessage && (
                      <div>
                        <p className="py-4 text-success">{successMessage}</p>
                      </div>
                    )}
                    {errorMessage && (
                      <div>
                        <p className="py-4 text-danger">{errorMessage}</p>
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="input-block">
                        <label>
                          Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              placeholder=""
                            />
                          )}
                        />
                        {errors.name && (
                          <p className="invalid-feedback">{errors.name.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block">
                        <label>
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="email"
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder=""
                            />
                          )}
                        />
                        {errors.email && (
                          <p className="invalid-feedback">{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block">
                        <label>
                          Phone number <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="phone"
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className={`form-control ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                              placeholder=""
                            />
                          )}
                        />
                        {errors.phone && (
                          <p className="invalid-feedback">{errors.phone.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block">
                        <label>
                          Comments <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="comments"
                          render={({ field }) => (
                            <textarea
                              {...field}
                              className={`form-control ${
                                errors.comments ? "is-invalid" : ""
                              }`}
                              rows={4}
                              cols={50}
                              placeholder=""
                            />
                          )}
                        />
                        {errors.comments && (
                          <p className="invalid-feedback">{errors.comments.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn contact-btn">{!isloading?'Send Enquiry':'Please Please'}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
