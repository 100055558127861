import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CornerDownLeft } from "react-feather";
import { all_routes } from "../router/all_routes";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { API_BASE_URL } from "../../environment";
import axios from "axios";


const route = all_routes;
const SignUp = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const [successMessage,setSuccessMessage] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [isloading,setIsLoading] = useState(false);
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_no: Yup.string()
    .required("Phone is required")
    .test('is-number', 'Phone must contain only numbers', (value) => {
      return /^[0-9]+$/.test(value);
    }),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('password')], 'Password and confirm password not matched')
});

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data:any) => {
    setIsLoading(true)
    setSuccessMessage('')
    setErrorMessage('')
    try{
      const response = await axios.post(`${API_BASE_URL}/users/add`,data)
  
      if(response.status != 400){
        setErrorMessage('')
        setSuccessMessage(response.data.message)
        window.scrollTo(0, 0);
        setTimeout(() => {
          navigate(route.login);
        }, 2000);
        setIsLoading(false)
      } else{
        setErrorMessage(response.data.message)
        setSuccessMessage('')
        window.scrollTo(0, 0);
        setIsLoading(false)
      }
    }
    catch(error:any){
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
        setSuccessMessage('')
        window.scrollTo(0, 0);
        setIsLoading(false)
      } else {
        setErrorMessage("An error occurred. Please try again later.");
        window.scrollTo(0, 0);
        setIsLoading(false)
      }
    }
  };


  return (
    <div>
      <div className="main-wrapper login-body">
       
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <div className="sign-group">
                  <Link to={route.home} className="btn sign-up">
                    <span>
                      <CornerDownLeft />
                    </span>{" "}
                    Back To Home
                  </Link>
                </div>
                <div className="text-center">
                  {successMessage && (
                    <span className="text-success">{successMessage}</span>
                  )}
                   {errorMessage && (
                    <span className="text-danger">{errorMessage}</span>
                  )}
                </div>
                <h1>Sign Up</h1>
                <p className="account-subtitle">
                  We will send a confirmation code to your email.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-block">
                    <label className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="firstname"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          {...field}
                        />
                        {errors.firstname && (
                          <div className="text-danger">{errors.firstname.message}</div>
                        )}
                        </>
                      )}
                    />
                    
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="lastname"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...field}
                        />
                        {errors.lastname && (
                          <div className="text-danger">{errors.lastname.message}</div>
                        )}
                        </>
                      )}
                    />
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          {...field}
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email.message}</div>
                        )}
                        </>
                      )}
                    />
                   
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="phone_no"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone"
                          {...field}
                        />
                        {errors.phone_no && (
                          <div className="text-danger">{errors.phone_no.message}</div>
                        )}
                        </>
                      )}
                    />
                   
                  </div>
               
                  <div className="input-block">
                    <label className="form-label">
                      Password <span className="text-danger">*</span>
                    </label>
                    <div
                      className="pass-group"
                      onClick={() => setIsToggle(!isToggle)}
                    >
                      <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                        type={isToggle ? "text" : "password"}
                        className="form-control pass-input"
                          placeholder="Enter Password"
                          {...field}
                        />
                        {errors.password && (
                          <div className="text-danger">{errors.password.message}</div>
                        )}
                        </>
                      )}
                    />
                      <span
                        className={`fas toggle-password ${
                          isToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="input-block">
                    <label className="form-label">
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <div
                      className="pass-group"
                      onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)}
                    >
                       <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field }) => (
                        <>
                        <input
                        type={confirmPasswordToggle ? "text" : "password"}
                        className="form-control pass-input"
                          placeholder="Enter Confirm Password"
                          {...field}
                        />
                        {errors.confirmPassword && (
                          <div className="text-danger">{errors.confirmPassword.message}</div>
                        )}
                        </>
                      )}
                    />
                      <span
                        className={`fas toggle-password ${
                          confirmPasswordToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-outline-light w-100 btn-size mt-1">
                   {isloading?'Please wait':'Sign Up'} 
                  </button>
                  {/* <Link
                    to={route.home}
                    className="btn btn-outline-light w-100 btn-size mt-1"
                  >
                    Sign Up
                  </Link> */}
                  <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">
                      Already registered?
                    </span>
                  </div>
                  {/* Social Login */}
                  {/* <div className="social-login">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/google.svg"
                          className="img-fluid"
                          alt="Google"
                        />
                      </span>
                      Log in with Google
                    </Link>
                  </div>
                  <div className="social-login">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/facebook.svg"
                          className="img-fluid"
                          alt="Facebook"
                        />
                      </span>
                      Log in with Facebook
                    </Link>
                  </div> */}
                  {/* /Social Login */}
                  <div className="text-center dont-have">
                    Already have an Account?{" "}
                    <Link to={route.login}>Sign In</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="log-footer">
          <div className="container-fluid">
            {/* Copyright */}
            <div className="copyright">
              <div className="copyright-text">
                <p>© 2024 Al-haramain Cab. All Rights Reserved.</p>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </footer>
        {/* /Footer */}
      </div>
    </div>
  );
};

export default SignUp;
