export const all_routes = {
 
  home: "/",
  signup: "/register",
  login: "/login",
  forgotpassword: "/authentication/forgot-password",
  resetpassword: "/authentication/reset-password",
  about: "/about",
  listinggrid: "/package",
  listinglist: "/package/listing-list",
  listingdetails: "/package/details",
  pricing: "/pages/pricing",
  faq: "/faq",
  gallery: "/pages/gallery",
  bloglist: "/blog/blog-list",
  bloggrid: "/blog/blog-grid",
  blogdetails: "/blog/blog-details",
  bookingcheckout: "/pages/booking-payment",
  booking: "/pages/booking",
  invoice: "/pages/invoice-details",
  ourteam: "/pages/our-team",
  testimonial: "/pages/testimonial",
  termsconditions: "/pages/terms-condition",
  privacypolicy: "/pages/privacy-policy",
  maintenance: "/pages/maintenance",
  comingsoon: "/pages/coming-soon",
  error404: "/pages/error-404",
  error500: "/pages/error-500",
  contact: "/contact",
  dashboard:"/user/user-dashboard",
  userbookings:"/user/user-bookings",
  reviews:"/user/user-reviews",
  wishlist:"/user/user-wishlist",
  messages:"/user/user-messages",
  userBookingComplete:"/user/user-booking-complete",
  userBookingCancelled:"/user/user-booking-cancelled",
  userBookingInprogress:"/user/user-booking-inprogress",
  userBookings:"/user/user-bookings",

  payment:"/user/user-payment",
  settings:"/user/user-settings",
  security:"/user/user-security",
  preference:"/user/user-preferences",
  notification:"/user/user-notifications",
  userintegration:"/user/user-integration",
  integration:"/user/user-integration",
  wallet:"/user/user-wallet",
  bookingcancelled:"/user/user-booking-cancelled",
  userBookingUpcoming:"/user/user-booking-upcoming",
};
