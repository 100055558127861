import React from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";

const Header = () => {
  const routes = all_routes;
  const location = useLocation();

  const header = [
    {
      tittle: "Home",
      showAsTab: false,
      separateRoute: true,
      routes: routes.home,
      hasSubRoute: false,
      showSubRoute: false,
    },
    {
      tittle: "Packages",
      showAsTab: false,
      separateRoute: true,
      routes: routes.listinggrid,
    },
    {
      tittle: "About us",
      showAsTab: false,
      separateRoute: true,
      routes: routes.about,
      hasSubRoute: false,
      showSubRoute: false,
    },
    {
      tittle: "FAQ",
      showAsTab: false,
      separateRoute: true,
      routes: routes.faq,
      hasSubRoute: false,
      showSubRoute: false,
    },
    {
      tittle: "Contact",
      showAsTab: false,
      separateRoute: true,
      routes: routes.contact,
      hasSubRoute: false,
      showSubRoute: false,
    },
  ];

  const pagesActiveClassArray = [
    '/authentication/register',
    '/authentication/login', '/authentication/forgot-password',
    '/authentication/reset-password', '/pages/booking-payment',
    '/pages/booking-payment', '/pages/invoice-details',
    '/pages/error-404', '/pages/error-500',
    '/pages/pricing', '/faq',
    '/pages/gallery', '/pages/our-team',
    '/pages/testimonial', '/pages/terms-condition',
    '/pages/privacy-policy', '/pages/maintenance',
    '/pages/coming-soon',
    '/about',
    '/contact' 
  ]
  return (
    <>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#">
                <span className="bar-icon">
                  {/* <span />
                  <span />
                  <span /> */}
                </span>
              </Link>
              <Link to={routes.home} className="navbar-brand logo">
                <ImageWithBasePath
                  src="assets/img/Al-Haramain-Logo-wb.svg"
                  className="img-fluid w-60"
                  alt="Logo"
                />
              </Link>
              <Link to={routes.home} className="navbar-brand logo-small">
                <ImageWithBasePath
                  src="assets/img/logo-small.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routes.home} className="menu-logo">
                  <ImageWithBasePath
                    src="assets/img/Al-Haramain-Logo-wb.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link id="menu_close" className="menu-close" to="#">
                  {" "}
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                {header.map((mainMenus:any, mainIndex:any) => {
                  return (
                    <React.Fragment key={mainIndex}>
                      {mainMenus.separateRoute ? (
                        <li
                          key={mainIndex}
                          className={
                            location.pathname.includes(mainMenus.routes || "") || mainMenus?.links?.includes(location.pathname) || (mainMenus.tittle == "Pages" && pagesActiveClassArray?.map((name) => name.includes(location.pathname)))
                              ? "active"
                              : ""
                          }
                        >
                          {/* {checkActiceClass(mainMenus)} */}
                          <Link to={mainMenus.routes}>{mainMenus.tittle}</Link>
                        </li>
                      ) : (
                        // <li className={`has-submenu ${mainMenus?.menu?.map((item)=> item?.routes).includes(location.pathname)  ? "active":""}`}>
                        <li className={`has-submenu`}>
                          <Link to="#">
                            {mainMenus.tittle}{" "}
                            <i className="fas fa-chevron-down"></i>
                          </Link>
                        </li>
                      )}
                    </React.Fragment>)
                })}
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
              <li className="nav-item">
                <Link className="nav-link header-login" to={routes.login}>
                  <span>
                    <i className="fa-regular fa-user" />
                  </span>
                  Sign In
                </Link>
              </li>
              
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
